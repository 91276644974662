// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import IMask from 'imask';

$(function() {
  // Date mask
  const $dateInputs = $('.js-date');
  var dateMaskOptions = {
    mask: 'd/m/Y',
    lazy: false,
    blocks: {
      d: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        maxLength: 2,
      },
      m: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        maxLength: 2,
      },
      Y: {
        mask: IMask.MaskedRange,
        from: 1900,
        to: 2200,
      }
    },
  };
  $dateInputs.each(function(_index, element){
    IMask(element, dateMaskOptions);
  });

  if (document.querySelectorAll("#payment-form").length) {
    import("../payments") // webpack will load this JS async
  } else if (document.querySelectorAll(".pages-faq").length) {
    import("../faq") // webpack will load this JS async
  }
});
